import React, {Dispatch, useContext, useEffect, useState} from 'react'
import {StorageHelpers} from '../helpers'

const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'

export interface StatesGlobalContextModel {
  statesGlobalStorage: any
  statesGlobal: any
  setStateGlobal: Dispatch<any>
}

const initStatesGlobalContextPropsStates = {
  statesGlobalStorage: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
  setStateGlobal: () => {},
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  const [statesGlobalStorage, setStateGlobalStorage] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )
  const [statesGlobal, setStateGlobal] = useState<{
    loadingFullPage: boolean
    verifyToken: boolean
    auth: {
      currentUser: any | undefined
      currentCompany: any | undefined
    }
  }>({
    loadingFullPage: false,
    verifyToken: false,
    auth: {
      currentUser: undefined,
      currentCompany: undefined,
    },
  })

  // BEGIN: Update global state when making the first API call.
  useEffect(() => {
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {})
    setStateGlobalStorage(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [])
  // END: Update global state when making the first API call.

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
        setStateGlobal,
        statesGlobalStorage,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
